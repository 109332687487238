import { ChatKoreanProjectMeta } from '@pixels/universal/model/apps/project-meta/chat-korean-project-meta';
import { EnvironmentsConfig } from '@pixels/universal/model/firebase/environment.model';
import { Phase } from '@pixels/universal/model/phase-model';
import { DEV_PROXY_URL } from '@pixels/universal/model/proxy/proxy.model';

export const environment: EnvironmentsConfig = {
  production: true,
  apiUrl: ChatKoreanProjectMeta.dev?.apiUrl ?? '',
  phase: Phase.dev,
  firebase: ChatKoreanProjectMeta.dev?.firebaseConfig ?? {},
  talkPlusAppId: ChatKoreanProjectMeta.dev?.talkPlusAppId ?? '',
  vapidKey: ChatKoreanProjectMeta.dev?.webPushKey ?? '',
  useEmulators: false,
  proxyUrl: DEV_PROXY_URL
};
